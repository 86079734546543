<template>
	<div style="padding-top: 20px">
		<div class="text-center" style="font-size: 22px; font-weight: bold; color: #444;">
			Welcome {{ bages.agentName }}
		</div>
		<div style="height: 20px;"></div>
		<h4>Case Details</h4>
		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-info">
						<div class="card-body">
							<div class="text-value">{{ bages.case_today }}</div>
							<div>Number of case today</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-primary">
						<div class="card-body">
							<div class="text-value">{{ bages.case_this_month }}</div>
							<div>Number of case this month</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-warning">
						<div class="card-body">
							<div class="text-value">{{ bages.case_last_month }}</div>
							<div>Number of case last month</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-danger">
						<div class="card-body">
							<div class="text-value">{{ bages.case_all_total }}</div>
							<div>Number of case all total</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div style="height: 20px;"></div>
		<h4>Commission Details</h4>
		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-info">
						<div class="card-body">
							<div class="text-value">{{ bages.commission_today }}</div>
							<div>Commission today</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-primary">
						<div class="card-body">
							<div class="text-value">{{ bages.commission_this_month }}</div>
							<div>Commission this month</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-warning">
						<div class="card-body">
							<div class="text-value">{{ bages.commission_last_month }}</div>
							<div>Commission last month</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-danger">
						<div class="card-body">
							<div class="text-value">{{ bages.commission_all_total }}</div>
							<div>Commission all total</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div style="height: 20px;"></div>
		<h4>Center Details</h4>
		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-info">
						<div class="card-body">
							<div class="text-value">{{ bages.center_today }}</div>
							<div>New center today</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-primary">
						<div class="card-body">
							<div class="text-value">{{ bages.center_this_month }}</div>
							<div>New center this month</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-warning">
						<div class="card-body">
							<div class="text-value">{{ bages.center_last_month }}</div>
							<div>New center last month</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-danger">
						<div class="card-body">
							<div class="text-value">{{ bages.center_all_total }}</div>
							<div>New center all total</div>
						</div>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>
<script>
export default {
	data() {
		return {
			bages: {},
		}
	},
	async created() {
		this.bages = await this.get("agent/dashboard-bages");
	}
}
</script>
<style scoped>
.card-body {
	flex: 1 1 auto;
	padding: 1.25rem;
}

.text-value {
	font-size: 1.3125rem;
	font-weight: 600;
}
h4 {
	padding-left: 15px;
	font-weight: bold;
	color: #454545;
}
</style>